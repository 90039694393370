.email-alert {
  display: none;
  clear: both;
  margin: $indent 0;
  text-align: left;
  color: $grey-dark;

  p {
    font-size: 90%;
  }

  .msg {
    padding: 0;

    p {
      font-size: 85%;
    }
  }

  .msg-title {
    font-size: 110%;
  }

  .icon-warning {
    position: relative;
    padding: 0 0 .5em 1.3em;
    color: $red;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      color: $red;
      font-size: $font-M;
    }
  }

  .msg-warning {
    margin-top: .5em;
    padding: .5em;
    border: 1px solid $red;
    color: $red;
  }
}
