@import "variable";

* {margin:0;padding:0;}
body {line-height:1;}
html {
  height: 100%;
}
article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary { display:block;}
strong, b {font-weight:bold;}
nav ul { list-style:none; }

a { 
  border: 0;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  outline: 0 none;
  color: #56ab48;
  
  &:hover {
    color: #000;
  }
}

ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

mark {
  background-color:#ff9;
  color:#000;
  font-style:italic;
  font-weight:bold;
}

del {text-decoration: line-through;}

abbr[title], dfn[title] {
  border-bottom:1px dotted #000;
  cursor:help;
}

table {border-collapse:collapse;border-spacing:0; width:100%;}

input, select {vertical-align:middle;}
input, button {font-size:90%;}
select{border:1px solid #CCC;}

img, fieldset {border:0;}

ul > li {list-style: none;}

table {border-collapse: collapse;}
td, th {padding:5px; vertical-align: top;}

h1 {font-weight:normal;}
h1, h2, h3, h4 {color:#000; font-size:120%;}

body {
  background: $bg_color;
  font-family: $sans-serif;
  line-height: 150%;
  color: rgba($black, .9);
  font-size: 90%;
  text-shadow: rgba(0,0,0,0.01) 0 0 1px;
  word-wrap: break-word;
  height: 100%;
}