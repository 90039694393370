@charset "utf-8";

@import "variable";
@import "component";

%font-ubuntu {
	font-family: $ubuntu;
}

%font-lato {
	font-family: $lato;
}

.heading-primary,
%heading-primary,
.heading-secondary,
%heading-secondary {
    text-transform: uppercase;
    font-family: $ubuntu;
    font-weight: normal;
    color: $text_color; 
    line-height:  130%;
    letter-spacing: .1em;
}

.heading-primary,
%heading-primary {
  font-size: $font-M1;
  @media (min-width: 590px) {
    font-size: $font-L;
  }
}

.heading-default,
%heading-default {
	text-transform: uppercase;
	letter-spacing: .1em;
}

.heading-sub,
%heading-sub {
  color: $grey-dark;
  font-weight: bold;
}

.header {
  font-family: $ubuntu;
  font-weight: lighter;
  line-height:  130%;
  font-size: $font-M1;
  color: $grey-medium;
}

.text-primary {
  color: $grey-medium;
  line-height: 150%;
}

%font-sub {
  color: rgba($black, .6) ;
}

.text-sub,
%text-sub {
  font-family: $sans-serif;
  color: $grey-light3;
  font-size: .9em;
}