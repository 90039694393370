@import "../fonts";

input,
select,
textarea {
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
  line-height: normal;
  @extend %border-box;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
input[type=checkbox],
input[type=radio],
input[type=file] {
  margin: 0;
  padding: 0;
}

.text::-moz-focus-inner,
.txt-field::-moz-focus-inner,
.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// Text Input
%txt-field,
.text,
.txt-field {
  width: 75%;
  padding: 0.5em;
  border: 1px solid $grey-medium-light;
  border-radius: 4px;
  background-color: $white;
  color: $text_color;
  font-family: $sans-serif;
  vertical-align: middle;
  line-height: 100%;

  &.error {
    border-color: $red ! important;
  }

  &[disabled],
  &[readonly] {
    border-color: darken($bg_color, 10%);
    background-color: $bg_color ! important;
    box-shadow: none;
  }

  &[disabled] {
    color: $grey-medium-light ! important;
    cursor: default;
  }

  &[readonly] {
    cursor: text;
  }

  &.full {
    width: 100%;
  }

  &.short {
    width: 12em;
  }

  &.number {
    width: 6em;
  }

  &.min {
    width: 3em;
  }
}

.text-field {
  @extend %border-box;
  @extend %txt-field;
  width: 100%;
  height: 50px;
  -webkit-appearance: none;
  background: $white;
  border-radius: 4px;
  border: 1px solid darken($grey-light2,10%);
  padding: 0 .7em 0 .7em;
  box-shadow: none;
  font-size: $font-default;
  vertical-align: middle;
  color: $text_color;

  &.field__icon {
      padding-left: 1.8em;
  }
}

.txt-field__l {
  font-size: $font-M1;
}

input[type="date"] {
  padding: 0.2em;
}

.label-icon {
    @include svg-before;
}

textarea {
  &.text,
  &.text-field,
  &.txt-field {
    min-height: 10em;
    padding: 0.7em;
  }
}

.select-field {
  height: 50px;
  border-radius: 4px;
  border: 1px solid darken($grey-light2,10%);
  padding: 0 2em 0 .7em;
  background-image: url('images/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right .5em top 50%, 0 0;
  background-color: transparent;
  background-size: .65em auto, 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  box-sizing: content-box;
}

select {
  margin: 0;
  border: 1px solid $grey-medium-light;
  height: 2.3em;
  box-shadow: 0 1px 0 $bg_color;
  white-space: nowrap;
  max-width: 100%;

  &.error {
    border-color: $red ! important;
  }
}

.calendar-field {
  background-image: url('images/cal.svg') !important;
  background-repeat: no-repeat !important;
  background-position: 10px 50% !important;
  padding-left: 2.5em !important;
}

.form-field-list {
  > li {
    padding-bottom: 1.5em;
  }
}

.form-label {
  margin-bottom: .5em;
  display: block;
  text-align: left;
  color: $grey-light3;
}

.txt-field-top {
  padding-bottom: .3em;
  margin: 0;
}

.txt-field-btm {
  margin: 0;
  font-size: $font_S;
  padding-top: .5em;
  line-height: 130%;
  color: $text_color;
}

// Button

%button,
.button {
  @extend %border-box;
  @extend %center;
  box-shadow:
      0 1px rgba($black, 0.08),
      0 0 1px rgba($white, 0.2) inset
  ;
  background-color: $bg_color;
  -webkit-appearance: none;
  -webkit-user-select: none;
  border-radius: 4px;
  margin: 0;
  padding: 0 0.5em;
  border: 1px solid darken($bg_color, 20%); 
  display: inline-block;
  overflow: visible;
  color: rgba($black, .7);  
  font: normal normal 100%/1.75em Ubuntu, Helvetica Neue, sans-serif;
  text-decoration: none;
  text-shadow: 0 1px 0 darken($bg_color, 10%);
  white-space: normal;
  cursor: pointer;
  outline: none;
  vertical-align: middle;

  &:hover {
    background-color: darken($bg_color, 5%); 
    box-shadow: 0 1px 2px rgba($black, 0.3);
    color: rgba($black, .7);
    border-color: darken($bg_color, 10%);
    text-decoration: none;
  }

  &:active {
    background-color: darken($bg_color, 5%); 
    box-shadow: 0 1px 2px rgba($black, 0.2) inset;
  }
}

.button.large {
  padding: .7em 1em;
}

.button.full-width {
  width: 100%;
}

/// Sub button
.sub {
  color: rgba($black, .7);
  background-color: $bg_color;
  box-shadow: none;
  text-shadow: none;

  &:hover {
    color: $text_color;
    background-color: darken($bg_color, 5%);
    box-shadow: none;
  }

  &:active {
    box-shadow: inset 0 0 1px rgba($black, 0.3);
    border-color: darken($bg_color, 5%);
    bbackground-color: darken($bg_color, 5%);
  }

  &.edit-save {
    border-color: darken($link_color, 10%);
    color: $bg_color;
    background: $link_color;

    &:hover {
      background-color: darken($link_color, 10%);
    }

    &:active {
      border-color: darken($link_color, 10%);
      background-color: darken($link_color, 10%);
    }
  }
}

/// Action button
%action,
.action {
  min-width: 11em;
  padding: 0.3em 1em;
}

/// Primary action button

%button-primary,
.primary {
  background-color: $link_color;
  box-shadow: 0 1px 0 rgba($white,0.18);
  border-color: darken($link_color,10%);
  color: $white !important;
  text-shadow: 0 1px 0 darken($link_color, 10%);
  
  &:hover {
    background-color: darken($link_color,5%);
    color: $white;
    border-color: darken($link_color,10%);
  }

  &:active {
    background-color: darken($link_color,5%);
    box-shadow: 0 0 1px rgba($black, 0.3) inset;
  }
}

/// Important action button

%button-important,
.button.important {
  background-color: $orange;
  border-color: darken($orange,10%);
  color: $white !important;
  text-shadow: 0 1px 0 darken($orange, 10%);

  &:hover {
    background-color: darken($orange,5%);
    color: $white !important;
    border-color: darken($orange, 10%);
  }

  &:active {
    background-color: darken($orange,5%);
    box-shadow: 0 0 1px rgba($black, 0.3) inset;
  }
}

%button-minimal,
.button.minimal {
  border: none;
  background: transparent;
  box-shadow: none;
  color: $link_color;
  text-shadow: none;
}

.button[disabled],
.button.disabled,
.button.loading {
  border-color: darken($bg_color, 10%) ! important;
  color: $grey-medium-light ! important;
  background-color: $bg_color ! important;
  box-shadow: none;
  text-shadow: none;
  cursor: default;

  &:hover {
    color: $grey-medium-light ! important;
  }

  &:active {
    box-shadow: none;
  }

  &.clear {
    background: transparent !important;
  }
}

.button.loading {
  position: relative;
  font-style: italic;
  text-transform: none;
  letter-spacing: normal;
  padding-left: 2em !important;

  .loader.small {
    position: absolute;
    top: calc(50% - 5px);
    left: 1em;
    display: inline-block;
    border: 2px solid $grey-medium-light;
    border-top: 2px solid transparent;
    animation: spin 1s linear infinite;
  }
}

.button.saved {
  background: transparent ! important;
  border: none;
  font-style: italic;
}

.button.clear {
  background: none;
  border: none;
  box-shadow: none;
  text-shadow: none;
  color: $link_color;
  clear: none;

  &:hover {
    background: none;
    border: none;
    box-shadow: none;
    text-shadow: none;
    color: $grey-medium;
  }
}

.button.outline {
  background-color: transparent;
  text-shadow: none;
  color: $link_color;
  border-color: $link_color;
}

.button.option {
  position: relative;
  height: 6em;
  padding: 0 .5em;
  margin-right: 1.5em;
  border: 1px solid $grey-light;
  color: $grey-medium;

  &:hover, &.checked {
    border: 1px solid $link_color;
  }

  &.checked:after {
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background-color: $link_color;
    color: $white;
    content: '\2713';
    visibility: visible;
    line-height: 20px;
  }
}

.button.button-icon {
  padding-left: 2.3em;
  background-repeat: no-repeat;
  background-position: .5em center;
  text-align: left;
}

// Don't need to use other button classes
.button-overlay {
  border-radius: 4px;
  background-color: rgba($black,.4);
  color: $white;
  text-shadow: none;
  display: inline-block;
  padding: .5em;

  &:hover {
    color: $white;
    background-color: rgba($black, .7);
  }
}

// Sticky footer button
%button-sticky-btm {
  width:100%; 
  position: fixed; 
  bottom: 0; 
  left: 0;
  border-radius: 0; 
  padding: 1em .7em;
  z-index: 9;
  border-top: 2px solid rgba($white,.6);
  border-right: none;
  border-left: none;
  @extend %heading-secondary;

  &.icon-arrow-right:after {
    padding-left: .7em;
  }
}

.button__sticky {
  @extend %button-sticky-btm;
  @media ( min-width: 650px ) {
    width: 20em;
    border-radius: 4px;
    position: static;
    margin: 1em auto 3em auto;
    display: block;
  }
}

.button__sticky-floating,
%button__sticky-floating { 
    @extend %button-sticky-btm;
    bottom: 1em;
    padding: 1em;
    margin: 0 5%;
    width: 90%;
    border-radius: 4px;
    font-size: $font-M;
    display: block;
}

.button-pencil {
  background-image: url('images/pencil.svg');
}

// Fields
.sub-form.required label:after,
.field.required .field-header label:after {
  @extend %required-mark;
}

.fs_alwaysnonrequiredtext.required label:after {
  display: none;
}

// Forms
.form-fields dl {
  padding: 5px 0;
}
.form-fields dt {
  width: 85px;
  float: left;
  color: #000;
}

.form-fields dd {
  display: block;
  float: left;
  color: rgba($black, .7);
}

.form-fields .txt-field {
  width: 200px;
}
.highlight,
.highlight td {
  background: #ffffe4;
}
textarea {
  padding: .4em;
  font-size: 100%;
  border-color: $grey-medium-light;
  border-radius: 4px;
  resize: vertical;
  line-height: 150%;
}
textarea:focus {
  outline: none;
  outline-offset: 0;
}
.placeholder {
  color: $grey-light;
}
input.checkbox,
input.radio {
  margin-right: 4px;
}

.checkbox-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

label.choice {
  cursor: pointer;
}

// Date & Time input fields
.field-2 {
  float: left;

  .date-field {
    width: 100%;
  }

  .small {
    display: block;
    padding-top: 4px;
    color: $grey-medium-light;
  }

  .time {
    width: 150px;
  }

  .time select {
    font-size: 100%;
    line-height: 150%;
  }
}

.field-date {
  width: 120px;
  margin-right:  .5em;
}



