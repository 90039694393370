#wrap {
  text-align: center;
  position: relative;
  z-index: 0;
  min-width: 980px;
  box-sizing: border-box;
}

#content-main,
.content-main {
  @extend %sticky-footer;
} 

#content-main {
  *zoom: 1;
  clear: both;
  padding: 18px 14px 14px;
  width: 980px;
  margin-top: 0;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  //position: relative;
}

.global-footer {
  min-height: $footer-height;
  box-sizing: border-box;
}

.col-sub {
  float: left;
  width: 300px;
}

.col-main {
  *zoom: 1;
  float: left;
  width: 610px;
}

.col-main:before,
.col-main:after {
  display: table;
  content: "";
}
.col-main:after {
  clear: both;
}

.box-main {
  width: 745px;
  float: left;
  padding: 35px;
  min-height: 300px;
}

.gap-bottom {
  margin-bottom: 1em;
}

.gap-bottom-large {
  margin-bottom: 2em;
}

.pad-bottom {
  padding-bottom: 1em;
}

// column & helpers
// ----------------
.row, 
.column {
  box-sizing: border-box;
}

.column {
    position: relative;
    float: left;
}


.row.row-spacer .column {
  margin-top: 25px;
}

.column + .column {
    margin-left: 1.6%;
}

.hidden {
  display: none;
}

@for $i from 1 through 12 {
  .column-#{$i} {
    width: ($singleColumnWidth * $i) + ($gutterMargin * ($i - 1));
  }
}

@media only screen and (max-width: $mobile_web_breakpoint) {
    .column-1, 
    .column-2, 
    .column-3, 
    .column-4, 
    .column-5, 
    .column-6, 
    .column-7, 
    .column-8, 
    .column-9, 
    .column-10, 
    .column-11, 
    .column-12 {
        width: auto;
        float: none;
    }

    .column + .column {
        margin-left: 0;
    }
}