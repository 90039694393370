
// Account Nav

// links under user name
.account-holder {
  @extend %clearfix;
  float: right;
  margin-left: .5em;
  width: auto;
  max-width: 11em;
  text-align: right;
}

.account-holder-name {
  @extend %ellipsis-text;
  text-align: right;
  display: block;
  position: relative;
  padding: .5em 1.5em .5em .5em;
  font-family: $ubuntu;
  line-height: 100%;
  background-color: $grey-medium-light;
  border-radius: 4px;
  color: $white;

  &.icon-triangle:after {
    font-size: $font-S;
    position: absolute;    
    top: .8em;
    right: .5em;
  }

  > a {
    color: $white;
    &:hover {
      color: $white;
    }
  }

  &:hover {
    background-color: $link-color;
  }
}

.nav-account.list-pop_content {
  right: 0;
  top: 2.5em;
  text-align: left;
  width: 14em;
  line-height: 150%;
  background-color: $white;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 11;

  &:before {
    content: none;
    right: 15px;
    left: auto;
    border-bottom-color: $grey-light;
  }
}

.nav-account_list {
  padding: .8em 0;
  border-bottom: 1px solid $grey-light2;

  &:last-child {
    border-bottom: none;
  }

  > li > a {
    display: block;
    padding: .7em 1.4em;
    color: $grey-light3;
    border-color: $grey-light;

    &:hover {
      color: $link_color;
    }
  }
}

.nav-account_list_heading {
  padding: 0.4em 1.4em;

  .heading-secondary {
    font-size: $font-S; 
    color: $grey-medium-light2;
    text-align: left;
  }
}

@media only screen and (max-width: $mobile_web_breakpoint) {
  .nav-row_item.list-pop,
  .account-holder.list-pop {
    position: static;
  }

  .nav-account.list-pop_content {
    position: fixed;
    bottom: 0;
    overflow: auto;
    left: 0;
    right: 0;
    top: 50px;
    width: 100%;
    border: none;
    border-radius: 0;
    border-top: 1px solid $grey-light;
    padding-bottom: 7em;
    box-shadow: none;
    &:before, &:after {
     right: 24px;
    }
  }

  .account-holder {
    width: auto;
  }

  .account-holder-name {
    max-width: 80px;
  }
}