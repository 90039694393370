@charset "UTF-8";

@import "modules/header_account";

.global-header {
  @extend %clearfix;
  background: $white;
  border-bottom: 1px solid $grey-light;
  text-align: left;
}

#container {
  max-width: 950px;
  height: 50px;
  margin: 0 auto;
  padding: .6em;
  box-sizing: border-box;
}

.svg-logo-peatix {
  float: left;
  width: 120px;
}

.svg-logo-peatix__s {
  width: 100px;
  padding-top: .2em;
}

#logo-s-logged {
  height: 36px;
  width: 44px;
}

.global-nav {
  @extend %clearfix;
  max-width: 950px;
  position: relative;
}

.global-nav,
.account-action {
  .divider {
    display: inline-block;
    color: $grey-light;
    padding: 0 .5em;
  }
}

.nav-row {
  @extend %clearfix;
  margin: 0;
  padding-left: 1em;
}

.nav-row__right { float: right; }
.nav-row__left { float: left; }

.nav-row_item {
  margin-right: 5px;
  float: left;
  line-height: 100%;
  vertical-align: middle;
  list-style: none;

  > a {
    display: block;
    padding: .4em 1em;
    color: $grey-medium-light;
    text-transform: uppercase;

    &:hover {
      color: $link_color;
      svg,
      .svg-circle-plus .fill-color {
        fill: $link_color;
      }
    }

    .nav-row_item_text {

      // Hide elements in header for small screens
      @media only screen and (max-width: 800px) {
          display: none;
      }
    }

    @media only screen and (max-width: 500px) {
      padding: .4em .5em;
    }
  }

  &.nav-row_item__login {
    > a {
      display: inline-block;
      padding-top: .7em;
      color: $link_color;
    }  
  }

  svg {
    fill: $grey-medium;
    width: 20px;
    height: 20px;
    vertical-align: middle;

    &.svg-circle-plus .fill-color {
      fill: $grey-medium;
    }
  }  
}

.msg-indicator {
  position: relative;

  .msg-new {
    position: absolute;
    top: -.3em;
    right: .1em;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
    min-width: 1.5em;
    height: 1.5em;
    padding: .3em 0 0 0;
    line-height: 100%;
    border-radius: 1em;
    background-color: $orange;
    color: $white;    
    font-size: $font-S;
  }

  .svg-msg {
    margin-top: .1em; // vertical adjustment for this icon
  }
}

@media only screen and (max-width: $mobile_web_breakpoint) {
  body.fixed {
    position: fixed;
    width: 100%;
  }
}
