@charset "UTF-8";
// Grid
$full_width: 960px; //also $L
$columns: 12;
$gutter: 20px;

$footer-height: 15em;

// Font
$sans-serif: "Helvetica Neue", Verdana, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "ＭＳ ゴシック", "Kozuka Gothic Pr6N", Osaka, "MS PGothic", sans-serif;
$sans-serif2: Verdana, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "ＭＳ ゴシック", "Kozuka Gothic Pr6N", Osaka, "MS PGothic", sans-serif;
$ubuntu: ubuntu, $sans-serif;
$light: "Helvetica Neue Light", "HelveticaNeue-Light", $sans-serif;
$lato: "Lato", $sans-serif;
$monospace: monospace;
$font-default: 1em;
$font-S: .8em;
$font-M: 1.1em;
$font-M1: 1.3em;
$font-M2: 1.6em;
$font-L: 2em;
$font-XL: 3em;
$font-XXL: 4em;

// Color
$text_color: #5a5d58;
$bg_color: #f5f5f7;
$link_color: #56ab48;

$black: #000;
$white: #fff;
$red: #CC0000;
$yellow: #FDF2CF; // msg-info former: #FFFED3

$grey-light: #d3d3d5;
$grey-light2: #f1f1f1;
$grey-light3: #6c6c6c;
$grey-medium-light: rgba($text_color, .6);
$grey-medium-light2: #9E9E9F;
$grey-medium: #636363;
$grey-dark: #333333;
$grey-dark2: #4A4A4A;

$light-green: #52ab46;
$medium-green: #4e933f;

$dark-transparent: rgba($black, .5);

$orange: #f7b930;

// Semantics

// Ratio
$ratio: 1.618; // Golden Ratio

// Margin
$indent: 1.618em;
$half-indent: ($indent / 2);
$default-margin: $indent 0;

// Breakpoint variables
$XS: 320px; // 
$S: 480px; // 
$M: 640px; // 
$L: $full_width;

// Avatar Sizes
$avatar-s: 32px;
$avatar-m: 44px;
$avatar-l: 112px;

// Inner Ticket borders
$ticket-border: 1px solid rgba($black, .25);

// Grid Layout
$mobile_web_breakpoint: 550px;
$tablet_breakpoint: 750px;
$gutterMargin: 1.6%;
$singleColumnWidth: 6.86666666667%;
