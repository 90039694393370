@charset "UTF-8";
@import "variable";
@import "component";

// TODO: replace below button classes with the ones in _form.scss


.btn-sub {
  @include horizontal-gradient(#f0efea, #d5d4d1);
  box-shadow:
      0 1px rgba(0, 0, 0, 0.08),
      0 0 1px rgba(255, 255, 255, 0.2) inset;
  display: inline-block;
  padding: 8px 25px 8px 25px;
  margin-right: 4px;
  border-radius: 4px;
  border: 1px solid #CBCAC8;
  text-align: center;
  color: #000;
  cursor: pointer;
  text-shadow: #FFF 1px 1px 1px;
  line-height: 120%;

  &:hover {
    @include horizontal-gradient(#ffffff, #dcdbd7);
    box-shadow: 
         0 1px 2px rgba(0, 0, 0, 0.2),
         0 1px 2px rgba(255, 255, 255, 0.5) inset
    ;
    color: #444;
    text-shadow: #FFF 1px 1px 1px;
  }
}

#tweet-Facebook.btn-sub {
  margin-right: 0px;
}

.btn-s {
  -webkit-appearance: none;
  border: none;
  border-radius: 4px;
  font-size: $font-S;
  background-color: $bg_color;
  background-image: none;
  padding: 8px;
  color: $text_color;
  cursor: pointer;

  &:hover {
    background-color:  $link_color;
    color: #FFF;
  }
}

.btn-xs {
  font-size: 85%;
  padding: 5px 10px;
}
.btn-xxs {
  font-size: 85%;
  padding: 3px 5px;
  border-top: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-right: 1px solid #999;
  border-bottom: 1px solid #999;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-s-on {
  background-color: #56ab48;
  color: #FFF;
}
.btn-add {
  background: url('images/btn-add.jpg') no-repeat 0 0;
  padding: 5px 0 5px 25px;
}
.btn-loading {
  font-weight: normal;
  font-style: italic;
}
.btn-box {
  padding-bottom: 30px;
}
.btn-outer {
  padding: 20px 0;
}

.btn-saved,
.btn-loading,
.btn-saved:hover,
.btn-loading:hover {
  position: relative;
  border-color: #9ea1a3 ! important;
  color: rgba($black, .7) ! important;
  background: #f3f3f3 ! important;
  box-shadow: none ! important;
  text-shadow: none ! important;
  cursor: default ! important;
}

.btn-sub {
  &[disabled] {
    border-color: #9ea1a3;
    color: rgba($black, .7) ! important;
    background: #f3f3f3 ! important;
    box-shadow: none;
    opacity: 0.3;
    text-shadow: none ! important;
    cursor: default ! important;
  }
}