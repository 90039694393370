@charset "UTF-8";

.global-message-sticky {
  opacity: 0.9;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  height: 0px;
  color: #000;
  z-index: 999;
  box-sizing: border-box;
}

.global-message-admin {
  top: 50px;
}

.global-message .msg, 
.global-message .msg-error,
.global-message .msg-success,
.global-message .msg-info,
.global-message .js-notification {
  position: relative;
}

.msg {
  position: relative;
  text-align: center;
}

.msg-info,
.msg-note,
.msg-success,
.msg-error {
  padding: 10px 20px;
  margin-bottom: 10px;
}

.msg-info {
  background: $yellow;
  border-color: darken( $yellow, 5% );
}

.msg-note {
  background: #dcdddd;
}

.msg-success {
  background: $yellow;
  border-color: darken( $yellow, 5% );
}

.msg-error {
  background: #FFD1D6;
  color: $red;
}

.msg-important {
  color: $red;
  font-weight: bold;
  text-align: center;
  font-size: 120%;
  line-height: 170%;
}

.error.txt-field {
  border: 2px solid $red;
  background: #FFF1F3;
}

.info-txt {
  background: #FFFAB8;
  padding: 5px 10px;
  margin-bottom: 20px;
  font-weight: bold;
}

.remove-x {
  background: url('images/cross.svg');
  background-size: 12px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  right: 1em;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}