// Language Switch
.lang-switch-wrap {
  margin-top: -.7em;   
  vertical-align: middle;
  text-align: left;
  display: inline-block;

  @media only screen and (min-width: $full_width) {
    float: left;
  }
}

.lang-switch {
  margin: 0;
  
  > li {
    list-style: none; // for top page
  }  

  a {
    display: block;
    color: $text_color;

    &:hover {
      color: darken($link_color, 10%);
    }
  }

  .lang-option {
    display: none; 
    padding: 1em;
  }
}

.lang-selected {
  padding: 1em;

  .icon-triangle {
    display: block;
    width: 10px;
    height: 15px;
    margin-right: 5px;
    overflow: hidden;
    float: left;
    text-decoration: none;
    @include transform(rotate(180deg));
  }

  .icon-triangle:before {
    display: block;
    width: 10px;
    height: 15px;
    font-size: 10px;     
  }
}

.lang-active {
  position: relative;
  margin-top: -3.5em;
  z-index: 8;
  border-radius: 2px;
  background-color: $white;

  > li > a {
    color: $text_color !important;
    &:hover {
      color: $link_color;
      text-decoration: none;
    }
  }

  .icon-triangle, .icon-triangle:before {
    display: none;
  }

  .selected-highlight {
    background: #CCC;
    padding-left: 15px;
    border-radius: 0 0 2px 2px;

    > a:hover {
      color: #444;
    }
  }

  .icon-checkmark:after {
    padding-left: 5px;
  }

  @media only screen and (min-width: $full_width) {
    &.lang-switch__event-view {
      left: inherit;
    }
  }
}
