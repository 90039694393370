@charset "UTF-8";

.list-pop {
  position: relative;
}

.list-pop_content {
  display: none;
  box-shadow: 0 1px rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 23px;
  z-index: 9;
  padding-left: 0;
  border-radius: 3px;
  border: 1px solid #444;
  background: #444;

  > li {
    list-style: none;
  }

  > li > span, > li > a {
    display: block;
    padding: 0.7em 1em;
    border-bottom: 1px solid #666;
    color: #fff;
  }

  > li > a {
    cursor: pointer;

    &:hover {
      background: #efefef;
      color: #444;
    }
  }

  > li.selected {
    background: #222;
  }

  > li:last-child > a,
  > li:last-child > span {
    border-bottom: none;
  }

  &:before {
    position: absolute;
    content: '';
    top: -9px;
    left: 10px;
    display: block;
    width: 1px;
    height: 1px;
    border-style: dashed dashed solid;
    border-color: transparent;
    border-width: 0 7px 7px;
    color: #666;
    z-index: 1000;
    visibility: visible;
    border-bottom-color: #444;
  }

  @at-root #{&}--align-right {
    width: 250px;
    top: 27px;
    right: 0;

    &:before {
      left: 82%;
    }
  }
}

// modifiers
// attendee-filter
.list-pop__message-link {
  float: right;
  padding-top: 0.4em;

  .icon-envelope {
    color: #444;
    &:hover {
      color: #000;
    }
  }

  .icon-envelope:before {
    display: block;
    float: left;
    padding-right: 5px;
    font-size: 20px;
  }

  .list-pop_arrow {
    left: 5px;
    top: 27px;
  }

  .list-pop_content {
    top: 34px;
  }

  @media ( max-width: $colspan08 ) {
    float: none;
  }
}


// Header
.list-pop__header {
  .list-pop_content {
    font-size: 1.2em;
    margin-top: 1em;
    width: 10.2em;
  }

  .icon-triangle:after {
    font-size: $font-S;
    margin-left: 0.3em;
    color: $text-color;
  }
}

// Footer
.list-pop__footer-button {
  .list-pop_content {
    top: -9.5em;
    left: 1em;

    &:before {
      bottom: -9px;
      top: auto;
      border-style: solid dashed dashed;
      border-width: 7px 7px 0 7px;
      border-top-color: #444;
    }

    @media (max-width: $full-width) {
      left: .5em;
      text-align: left;
    }
  }
}

