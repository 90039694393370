.avatar {
  float: left;
  margin-right: 10px;
}

.avatar-small, .avatar-default, .avatar-large {
	display: block;
	border-radius: 50%;
	img {
		border-radius: 50%;
	}
}

.avatar-small {
	width: 32px;
	height: 32px;
	float: left;
	margin-right: 10px;
	img {
		width: 32px;
		height: 32px;
	}
}

.avatar-default {
	width: 44px;
	height: 44px;
	float: left;
	margin-right: 10px;
	img {
		width: 44px;
		height: 44px;
	}
}

.avatar-large {
	width: 112px;
	height: 112px;
	img {
		width: 112px;
		height: 112px;
	}
}

.style-guide-avatar-list {
	height: 130px;
}

.style-guide-avatar {
	display: inline-block;
	float: left;
	width: 80px;
	height: 80px;
	.avatar-small {
		margin-top: 40px;
	}
	.avatar-default {
		margin-top: 34px;
		margin-left: -8px;
	}
}