@charset "UTF-8";

.btn-additional-handle .btn-additional-data {
  display: none;
  top: 20px;
  left: 1px;
  width: auto;
}
.btn-additional-handle:hover .btn-additional-data {
  display: block;
}
.btn-additional-handle:hover .btn-additional-data ul li {
  padding: 0 0 5px 0;
}
.btn-additional-handle:hover .btn-additional-data ul li a {
  display: block;
  width: 150px;
  text-align: left;
}
.btn-additional-handle:hover .btn-additional-trigger {
  background: #333;
  color: #FFF;
  text-shadow: none;
}