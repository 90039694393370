@import "grid";

.label-small,
%label-small {
  display: inline-block;
  padding: 0 0.4em;
  margin-right: 2px;
  border-radius: 2px;
  background: $grey-medium;
  color: $white;
  font-size: $font-S;
}

// Clear Fix
@mixin clearfix {
  zoom: 1;
  &:before,
  &:after {
    display: block;
    height: 0;
    visibility: hidden;
    content: "\0020";
  }
  &:after {
    clear: both;
  }
}


// Box Sizing
@mixin box-sizing($model) {
  @each $vendor in -moz-, -webkit-, null {
    #{$vendor}box-sizing: $model;
  }
}

// Square
@mixin square($size) {
  width: $size;
  height: $size;
}

// Opacity
@mixin opacity($degree) {
  $degree_num: ($degree * 100);
  opacity: $degree;
  -ms-filter: 'alpha(opacity = #{$degree_num})';
  filter: alpha(opacity = $degree_num);
}

// Transform
@mixin transform($functions) {
  @each $vendor in -ms-, -webkit-, null {
    #{$vendor}transform: $functions;
  }
}

// Gradient
/// Vertical
@mixin vertical-gradient($start, $end) {
  //background-color: $end;
  @each $vendor in -ms-, -moz-, -webkit-, null {
    background-image: #{$vendor}linear-gradient($start, $end);
  }
}

/// Horizontal
@mixin horizontal-gradient($start, $end) {
  background-color: $end;
  @each $vendor in -ms-, -moz-, -webkit-, null {
    background-image: #{$vendor}linear-gradient($start, $end);
  }
}

//Vendor Prefixes
@mixin vendor-prefix($name, $argument) {
  -webkit-#{$name}: #{$argument};
  -ms-#{$name}: #{$argument};
  -moz-#{$name}: #{$argument};
  -o-#{$name}: #{$argument};
  #{$name}: #{$argument};
}

@mixin text-shadow-light {
  text-shadow: 0 1px 0 rgba($black, .2);
}

// White box 
@mixin white-box($padding) {
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  padding: $padding;
  background: #fff;
  border-radius: 3px;
}

// User item
@mixin user-item($avatar-size: "44px") {
  @extend %clearfix;
  padding: 10px;
  border-top: 1px solid rgba($black, .1);
  background: $white;

  .avatar-pic {
      float: left;
      margin-right: 1em;
      border-radius: 50%;
    }

  .user-item_details {
    color: rgba($black, .5);
    margin-left: $avatar-size + 5px;
  }
}

// Buttons
@mixin button-white {
  @include icon-only(30px);
  @include text-shadow-light;
  padding: 7px;
  background: rgba($white, .4);
  border-radius: 3px;
  color: $white;
  line-height: 200%;
  box-shadow: 0 1px 2px rgba($black, 0.3);
}

@mixin button-mobile-main {
    display: block;
    width: 90%;
    height: 44px;
    padding: .5em 1em;
    margin: .8em auto 0 auto;
    max-width: 300px;

    &[disabled],
    &[disabled] .icon-arrow-right  {
        color: lighten($grey-medium, 40%)!important;
    }
}

// Media Query mixin
@mixin MQ($canvas) {
  @if $canvas == XS {
    @media only screen and (min-width: $XS) and (max-width: $S - 1) { @content; }
  }
  @else if $canvas == S {
    @media only screen and (min-width: $S) and (max-width: $M - 1) { @content; }
  }
  @else if $canvas == M {
    @media only screen and (min-width: $M) and (max-width: $L - 1) { @content; }
  }
  @else if $canvas == L {
    @media only screen and (min-width: $L) { @content; }
  }
}

// Corner Ribbon
@mixin corner-ribbon($color) {
  $red: red($color);
  $green: green($color);
  $blue: blue($color);
  $brightness: ($red + $green + $blue);

  position: absolute;
  top: -3px;
  right: -3px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  font-size: 90%;

  .ribbon {
    display: block;
    position: relative;
    left: -11px;
    top: 24px;
    padding: 5px 0;
    width: 130px;
    @include transform(rotate(45deg));
    @include vertical-gradient(lighten($color, 10%), darken($color, 8%));
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
    @extend %border-box;
    font-weight: bold;

    @if $brightness <= 280 {
      color: rgba(255, 255, 255, 0.85);
      text-shadow: -1px -1px 0 darken($color, 20%);
    }
    @else {
      color: rgba(0, 0, 0, 0.85);
      text-shadow: 1px 1px 0 lighten($color, 20%);
    }

    &:before,
    &:after {
      position: absolute;
      bottom: -3px;
      content: "\0020";
      border-top: 3px solid darken($color, 30%);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}

// Image Replacement
@mixin ir {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

// Icons
%icon {
  font-family: 'peatix';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}

// Icons -- probably a better way to integrate _icons.scss
@mixin icon-cross {
  &:before {
    content: "\78";
    @extend %icon;
  }
}

// Icon without text
@mixin icon-only($icon-size) {
  display: block;
  width: $icon-size;
  height: $icon-size;
  overflow: hidden;

  &:before {
    display: block;
    width: $icon-size;
    height: $icon-size;
    font-size: $icon-size;
  }
}

// SVG icon before
@mixin svg-before {      
    position: absolute;
    top: 1em;
    left: .7em;
    color: $link_color;

    svg {
        fill: $link_color;
    }

    @media screen and (min-resolution: 3dppx) {
        top: .85em;
    }
}

// Flexslider next-prev buttons
@mixin flex-direction-nav {
  .flex-direction-nav > li {
     > a {
      background: rgba($black, .4);
      width: 3em;
      height: 3em;
      border-radius: 50%;

      &:after {
        position: absolute;
        top: .5em;
        left: .5em;
        padding: 0;
        font-family: 'peatix';
        content: attr(data-icon);
        speak: none;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-decoration: none;
        text-shadow: none;
        line-height: 1;
        font-size: 1.5em;
        -webkit-font-smoothing: antialiased;
        color: #fff;
        display: block;
        width: 1.5em;
        height: 1.5em;
        overflow: hidden;
      }  
    }

    .flex-prev {
      left: 0;
    }

    .flex-next {
      right: 0;
    }

    .flex-next:after {
      content: "\3e";
      text-align: left;
    }

    .flex-prev:after {
      content: "\3c";
      text-align: left;
    }
  }
}

// Mobile Secondary Content 
// used in mec, mobile event view
@mixin  mobile-secondary-layout {
  background-color: $white;
  .content {
      max-width: 500px;
      margin: 0 auto;
  }
}

/// Extend
%sticky-footer {
  margin-bottom: -$footer-height;
  min-height: 100%;
  box-sizing: border-box;

  &:before,
  &:after {
      display: block;
      content: "";
    }

    &:after {
        clear: both;
        min-height: $footer-height;
    }
}

%border-box {
  @include box-sizing(border-box);
}

%tap-not-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

%ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%clearfix {
  @include clearfix;
}

%center {
  text-align: center;
}

%hide {
  display: none;
}

%ir {
  @include ir;
}

%required-mark {
  position: relative;
  top: -3px;
  display: inline-block;
  content: "\FF0A";
  margin-left: 0.5em;
  font-size: 50%;
  color: $red;
}