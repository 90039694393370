@charset "UTF-8";

@import "functions";
@import "variable";
@import "grid";
@import "component";

@import "reset";
@import "forms/form";
@import "forms/buttons";
@import "modules/email_alert";
@import "modules/footer";
@import "modules/global_message";
@import "modules/header";
@import "modules/list-pop";
@import "modules/btn-additional-handle";
@import "modules/layout";
@import "modules/avatar";

@import "modules/print";

// @import "debug";

strong strong {
  color: $red;
}

.hide-text {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.cf {
  @include clearfix;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 28px;
  box-sizing: border-box;
}

.hint {
  margin: 0.2em 0 0;
  color: #666;
  font-size: $font-S;
}
/************************
- COMMON
- EVENT
- MODAL WINDOW CONTENT
- LANGUAGE
- PRINT
**********************/

/************************
COMMON
**********************/
.ir {
  @include ir;
}

.loader {
    margin: 0 auto;
    border: 2px solid $grey-medium-light; /* Light grey */
    border-top: 2px solid transparent; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;

    &.absolute {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;

      &.left {
        left: 10px;
      }

      &.right {
        right: 10px;
      }
    }

    &.full-page {
      display: block;
      margin: 5em auto;
    }

    &.small {
      width: 10px;
      height: 10px;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.full-page-loader-layout {
  padding: 10em 0;
  text-align: center;
}

#tweet-Facebook.btn-sub {
  margin-right: 0px;
}

.sub-link {
  color: #999;
  font-weight: normal;
  font-size: 85%;
}

.sub-link:hover {
  text-decoration: underline;
}
.sub-text {
  color: $grey-medium-light;
}
.small {
  font-size: 85%;
  color: #666;
  line-height: 130%;
}
.sub-link {
  font-size: inherit;
  color: #666;
  text-decoration: underline;
}
.em {
  color: #CC3300;
}
.center {
  text-align: center;
}
.box-grey {
  margin: 10px 0 20px 0;
  background: #EFEEE7;
  padding: 10px;
  border-radius: 4px;
}

.box-white {
  margin: 1em 0 1.5em 0;
  background: $white;
  padding: 2em;
  border-radius: 4px;
  border: 1px solid $grey-light;
}

.box-white-borderless {
  background: $white;
  padding: 2em;
}

.data {
  margin-bottom: 30px;
}
.data td {
  background: #EFEEE7;
  border-bottom: 1px solid $bg_color;
}
.link-ext {
  background: url('images/icon-external-link.gif') no-repeat right;
  padding-right: 10px;
}
.right {
  float: right;
}
.left {
  float: left;
}
.clear {
  clear: both;
}
.none {
  display: none;
}

.private {
  background: url('images/lock.svg') no-repeat 0 0;
  padding-left: 15px;
}

.tags {
  font-size: 85%;
  color: #999;
  font-family: "ubuntu", Helvetica, Arial, Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "ＭＳ ゴシック", "Osaka", "MS PGothic", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.check {
  background: url('images/icon-check-s.png') no-repeat 0 5px;
}

.btn-additional-handle {
  position: relative;
  padding-right: 0;
}

.peatix {
  color: $link_color;
  font-family: ubuntu, Helvetica, Arial, Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "ＭＳ ゴシック", "Osaka", "MS PGothic", sans-serif;
  font-weight: bold;
}

.important,
.error-text {
  color: $red;
}

.event-id {
  font-size: $font_S;
  color: $grey-light3;
  padding-bottom: .5em;
}

.svg-lock-wrap {
  width: 12px;
  display: inline-block;
  vertical-align: middle;
  margin: -.5em .5em 0 0;
}

.svg-lock {
  width: 100%;
  background-image: url('images/lock.svg');
  background-size: cover;
  height: 0;
  padding-bottom: calc(100% * 22 / 16);
}

.remove-x {
  background: url('images/cross.svg');
  background-size: 12px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  right: 1em;
  opacity: 0.5;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.list-bullet {
  margin-left: 1em;

  &_item {
    list-style: disc;
    padding-bottom: .5em;
  }
}

// EVENT
/*****EVENT PUBLIC / EVENT ORGANIZER VIEW *****/
.box-wide {
  padding-right: 20px;
}
.box-narrow {
  width: 130px;
  float: left;
}
.base-head {
  padding: 20px 20px 0 20px;
}

.event {
  padding-top: 17px;
  margin-bottom: 10px;
  position: relative;
  width: 590px;
}

.event p {
  padding-bottom: 5px;
}
.event .embed p.attribution {
  text-align: right;
  font-size: 85%;
}
.event blockquote {
  margin: 10px 10px 20px 35px;
}
.event iframe body {
  background: $bg_color;
}

.btn-event-img span {
  background: url('images/icon-image-upload.png') no-repeat 0 50%;
  padding: 8px 0 8px 55px;
  display: inline-block;
}

.update-input-form {
  display: none;
}
.update-input {
  width: 430px;
  padding: 10px;
}

.event-action {
  text-align: center;
  margin-bottom: 30px;
  padding: 15px 0;
}
.event-action #draft-warning {
  display: block;
  color: #000;
  padding: 15px;
  font-size: 120%;
}
.event-action #draft-warning.none {
  display: none;
}

.share-txt {
  font-size: 85%;
}
.share-txt-long {
  width: 270px;
}

.fb-comment-section {
  background: #FFF;
}

.attendees {
  padding: 20px 0;
}
.attendees em {
  font-size: 200%;
  font-style: normal;
}

.btn-further-actions li a {
  display: block;
  margin-bottom: 6px;
}

.editable {
  cursor: pointer;
  background: url('images/icon-edit.png') no-repeat right 10px;

  &:hover {
    background-color: #ffffe4;
  }
}

.editable.subtitle-box {
  background-position-y: 5px;
}

.editable.empty-field {
  background: none;
}
.editable.empty-field:hover {
  background-color: transparent;
}
.no-highlight:hover {
  background: none;
}

.edit-btns {
  padding: 5px 0 5px 0;
}
.btn-s-em,
.edit-save,
.edit-save-real {
  background-color: $link_color;
  color: #FFF;
}
.btn-s-em:hover,
.edit-save:hover,
.edit-save-real:hover {
  background: #000;
}
.btn-s-em {
  line-height: 110%;
}
.title-box .edit,
.event .edit,
.subtitle-box .edit {
  float: none;
}
#field-event-name .event-name-ttl {
  line-height: 130%;
  font-size: 30px;
  font-weight: 500;
}

.event-msg-status {
  color: $red;
  margin-top: 15px;
  padding: 5px 0;
}
.event-msg-alert,
.event-msg-close {
  color: $red;
}
.btn-delete span {
  visibility: hidden;
}

.ttl-alert {
    padding-left: 35px;
    line-height: 150%;
    font-size: $font-M1;
    font-weight: normal;
    color: $text_color;

    &:before {
      position: absolute;
      margin-left: -35px;
      color: $orange;
      font-size: $font-M2;
    }
}

.cleditorMain .event-description-help {
  display: block;
}
.note {
  text-align: center;
}

.currency {
  font-size: 85%;
  font-weight: normal;
}
.editform {
  display: none;
}

.event-description-help em {
  color: $red;
}
.cutoff-date {
  color: #999;
  font-size: $font-S;
}

#event-organizer_logo .small {
  color: #999;
}

/************************
MODAL WINDOW CONTENT
**********************/
.window {
  background: #FFF;
  overflow: hidden;
}
.window-iframe {
  width: 520px;
  height: 350px;
}
.window-head {
  text-align: center;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
  position: relative;
}
.window-head h2 {
  line-height: 130%;
  font-size: 120%;
}
.window-btm {
  clear: both;
  border-top: 1px dotted #CCC;
  padding-top: 10px;
  margin-top: 10px;
  text-align: center;
  position: relative;
}
.window-btm .siteseal {
  position: absolute;
  left: 80px;
}

.back {
  float: left;
  background: transparent url('images/arrow-db.gif') no-repeat 0 50%;
  padding-left: 10px;
}
.window .msg-info,
.window .msg-success,
.window .msg-error {
  border-radius: 4px;
  border: 1px solid;
}
.window .msg-info,
.window .msg-success {
  border-color: #FFFED3;
}
.window .msg-error {
  border-color: #FF777C;
}
.window-wrap h3 {
  text-align: center;
}

.window .share-ttl {
  width: 170px;
}

/******Cancellation Process******/
.msg-strong {
  font-weight: bold;
  font-size: 120%;
  text-align: center;
  margin: 20px 30px;
  line-height: 170%;
}

/************************
EVENT ORGANIZER SECTION
**********************/
.auto-expand {
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: #FFF;
}
.auto-expand textarea {
  width: 100%;
  border: none;
  resize: none;
}
.form-fields .email-message .auto-expand {
  margin-top: 10px;
}

.multi-btn-row {
  margin-top: 20px;
}

.email-to-organizer {
  float: left;
}

.window-communication-form {
  height: 120px;
}

.invite-from,
.email-from dd {
  width: 550px;
}

#update-form .form-fields dd {
  width: 270px;
}
.form-section {
  padding-bottom: 30px;
}
.btn-close {
  float: right;
  background-image: url('images/btn-close.png');
  width: 16px;
  height: 16px;
  margin-top: 4px;
}
.btn-close:hover {
  opacity: 0.5;
}
.tos a,
#tos-warning a {
  color: #666;
}
.tos a:hover,
#tos-warning a:hover {
  color: $link_color;
}
.tos {
  padding-top: 20px;
}

/************************
LANGUAGE
**********************/
.ja nav .logged-name {
  width: 190px;
  padding-left: 0;
}
.ja nav .logged-name .logged-name-name {
  width: 160px;
}
.ja .col-s-b .box-fee {
  padding-top: 10px;
  height: 60px;
  line-height: 180%;
}
