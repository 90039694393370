@charset "UTF-8";

@import "component";
@import "modules/language_switcher";

.global-footer-wrap {
  clear: both;
  padding: .5em 0;
  background-color: darken($bg_color, 4%);
  text-align: center;
}

.global-footer {
  @extend %clearfix;
  width: $full-width;
  padding: 1em 0;
  margin: 1em auto 0 auto;
  text-align: left;
  color: rgba($black, 0.4);

  .social-media {
    @extend %clearfix;
    width: 90px;
    margin-right: 1em;
    float: left;

    @media only screen and (max-width: $full_width) {
      float: none;
      display: block;
      margin: 0 auto;
    }

    > a {
      @include icon-only(25px);
      margin-right: 5px;
      float: left;
      color: rgba($black, 0.3);
      &:hover {
        color: darken($link_color, 10%);
      }
    }
  }

  @media only screen and (max-width: $full_width) {
    width: auto;
    text-align: center;

    .lang-switch-wrap {
      margin: 1em auto 0 auto;
    }
  }
}

.footer-nav {
  @media only screen and (max-width: $full_width) {
    margin-bottom: 1em;
    font-size: $font_S;
  }
}

.footer-nav-item {
  display: inline-block;
  margin: 0 1.5em 1em 0;
  position: relative;

  &:after {
    position: absolute;
    right: -9px;
    top: 0;
    display: block;
    margin-left: 8px;
  }

  a {
    color: $text-color;
    &:hover {
      color: darken($link_color, 10%);
    }
  }
}

.footer-button-main-wrap {
  float: right;
  @media (max-width: $full-width) {
    float: none;
    display: inline-block;
  }
}

.footer-button-main {
  font-weight: normal;
  text-transform: uppercase;
  font-family: $ubuntu;
  letter-spacing: 0.1em;  
  background-color: rgba($black, 0.2);
  border-radius: 3px;
  padding: 1em 4em;
  margin-left: 1em;
  display: inline-block;
  color: $text_color;

  &:hover {
    background-color: rgba($black, 0.3);
    color: rgba($black, 8);
    text-decoration: none;
    -webkit-transition: background-color 1s ease-in-out; 
    transition: background-color 0.5s ease-in;  
  }

  @media (max-width: $full-width) {
    margin: 0 .5em 1em .5em;
  }
}

.footer-legal {
  clear: both;
  width: 100%;
  margin-top: 1em;
  padding-top: 2em;
  font-size: $font_S;

  .footer-nav-item {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: $full_width) {
    float: none;
  }
}

.p-mark {
  float: right;
  margin-top: -2em;
}

.p-mark {
  float: right;
  margin-top: -1.5em;
}

.p-mark-logo_link {
  display: block;
}

.p-mark_logo {
  width: 5.5em;
}

@media only screen and (max-width: $full_width) {
  .p-mark {
    float: none;
    margin-top: 1em;
  }
}